<template>
  <App left-arrow>
    <div id="card-container">
      <div class="user-wrap">
        <div class="brand">高合</div>
        <div class="name">小涛同志</div>
        <div class="post">出行顾问</div>
        <div class="contact-way">
          <ul>
            <li>
              <img src="../../assets/images/icon/icon_mobile@2x.png" alt="mobile">
              <span>18000000000</span>
            </li>
            <li>
              <img src="../../assets/images/icon/icon_email@2x.png" alt="email">
              <span>123@qq.com</span>
            </li>
            <li>
              <img src="../../assets/images/icon/icon_location@2x.png" alt="location">
              <span>SHANGHAI</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="desc-wrap">
        <p>您好，</p>
        <p>我是高合 <b>出行顾问 王小涛</b></p>
        <p>这是我的名片，请惠存</p>
        <p>谢谢。</p>
      </div>
      <div class="card-footer">
        <p class="tips">长按识别二维码，收下名片</p>
        <div class="qrcode"></div>
      </div>
    </div>
    <div class="footer">
      <button type="button">
        <img src="../../assets/images/icon/icon_qiyewx@2x.png" alt="">
        企业微信
      </button>
      <button type="button">
        <img src="../../assets/images/icon/icon_save@2x.png" alt="">
        保存相册
      </button>
    </div>
  </App>
</template>
<script>
export default {
  name: 'businessCard'
}
</script>
<style lang="less" scoped>
#app {
  padding: 0 24px;
}
/deep/ .van-nav-bar {
  background-color: transparent;
}
#card-container {
  margin-top: 40px;
  padding: 40px;
  border-radius: 10px;
  background-color: #fff;

  .user-wrap {
    padding: 35px 0 30px 42px;
    margin: 0 auto;
    line-height: 72px;
    background: #fff url('../../assets/images/card@2x.png') bottom right no-repeat;
    background-size: auto 100%;
    box-shadow: 0px 0px 15px rgba(110, 110, 110, 0.16);

    .brand {
      height: 30px;
      line-height: 30px;
      font-size: 22px;
      color: #0F0F13;
    }

    .name {
      position: relative;
      margin-top: 32px;
      height: 67px;
      line-height: 67px;
      font-size: 48px;
      font-weight: 800;
      color: #0F0F13;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -42px;
        width: 8px;
        height: 33px;
        margin-top: -16px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: #0F0F13;
      }
    }

    .post {
      display: inline-block;
      margin-top: 10px;
      padding: 0 10px;
      height: 34px;
      line-height: 34px;
      border-radius: 4px;
      background-color: #F0F2F6;
      font-size: 22px;
      color: #5F5F6A;
    }
    
    .contact-way {
      padding-top: 47px;

      li {
        display: flex;
        align-items: center;
        height: 30px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          margin-right: 10px;
          width: 20px;
          height: auto;
        }

        span {
          font-size: 22px;
          color: #5F5F6A;
        }
      }
    }

  }

  .desc-wrap {
    margin-top: 50px;
    font-size: 28px;
    line-height: 42px;
    color: #5F5F6A;

    b {
      color: #0f0f13;
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 147px;

    .tips {
      position: relative;
      padding-left: 8px;
      font-size: 24px;
      line-height: 33px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 25px;
        margin-top: -12px;
        background-color: #DA3738;
      }
    }
  }
}
.footer {
  position: fixed;
  bottom: 97px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 324px;
    height: 96px;
    line-height: 96px;
    border-radius: 92px;
    font-size: 32px;
    border: 0;

    img {
      width: 40px;
      height: auto;
      margin-right: 10px;
    }

    &:first-child {
      color: #DA3738;
      background-color: #DADEED;
      margin-right: 32px;
    }

    &:last-child {
      color: #fff;
      background-color: #DA3738;
    }
  }
}
</style>